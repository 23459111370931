import { useEffect, useState } from "react";
import logo from "../../../assets/images/home/Logo.png";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { Link, useLocation } from "react-router-dom";

const Navmenu = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { pathname } = useLocation();

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
  // get scroll position by adding event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      className={`transition-all z-50 flex items-center justify-between py-3 px-5 bottom-[100] left-[50%] translate-x-[-50%] ${
        scrollPosition > 100
          ? "w-10/12 bg-white fixed top-0 rounded-full border"
          : "w-10/12 absolute translate-y-8"
      } `}
    >
      <div className="nav-links">
        <ul className="flex items-center justify-start gap-5 xl:gap-10">
          <li className="text-xl text-primary-blue">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="text-xl text-primary-blue">
            <Link to={"/about"}>About Us</Link>
          </li>
          <li className="text-xl text-primary-blue">
            <Link to={"/shop"}>Shop</Link>
          </li>
          <li className="text-xl text-primary-blue">
            <Link to={"/contact"}>Contact Us</Link>
          </li>
        </ul>
      </div>
      <div className="nav-logo flex-1">
        <img
          draggable="false"
          className={`h-full transition-all ${
            scrollPosition > 100 ? "w-32" : "w-44"
          } object-contain m-auto`}
          src={logo}
          alt="Mahahandloom.com mahahandloom"
        />
      </div>
      <div className="nav-icons flex-1 flex items-center justify-center gap-5 xl:gap-10">
        <SearchIcon fontSize="large" className="text-primary-blue" />
        <Link to={"/wishlist"}>
          <FavoriteBorderOutlinedIcon
            fontSize="large"
            className="text-primary-blue"
          />
        </Link>
        <Link to={"/account"}>
          <PermIdentityOutlinedIcon
            fontSize="large"
            className="text-primary-blue"
          />
        </Link>
        <Link to={"/cart"}>
          <ShoppingBagOutlinedIcon
            fontSize="large"
            className="text-primary-blue"
          />
        </Link>
      </div>
    </nav>
  );
};

export default Navmenu;
