import india from "../../../assets/images/india.png";
import translate from "../../../assets/images/translate.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Navmenu from "./Navmenu";
import topPattern from "../../../assets/images/home/header-pattern.png";

const TopHeader = () => {
  return (
    <header className="relative">
      <div className="bg-primary-pink">
        <p className="text-white capitalize text-center p-2 md:text-lg text-sm">
          Use Code: "Handloom" to avail 5% off on your first order
        </p>
      </div>
      <div className="relative flex justify-between py-1 md:py-3 px-5 md:px-20 bg-white">
        <div className="social-media-icons">
          <InstagramIcon
            className="border hover:p-[4px] rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
            fontSize="large"
          />
          <FacebookIcon
            className="border hover:p-[4px] rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
            fontSize="large"
          />
          <TwitterIcon
            className="border hover:p-[4px] rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
            fontSize="large"
          />
          <YouTubeIcon
            className="border hover:p-[4px] rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
            fontSize="large"
          />
          <LinkedInIcon
            className="border hover:p-[4px] rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
            fontSize="large"
          />
        </div>
        <div className="flex gap-3">
          <button className="bg-primary-blue text-white rounded p-1  text-xs md:text-base">
            GET E-BROCHURE
          </button>
          <div className="flex gap-0 currency-change-wrapper  text-xs md:text-base">
            <img
              draggable="false"
              className="h-full w-9 object-contain"
              src={india}
              alt="india flag"
            />
            <select
              className="rounded border p-1 bg-gray-100"
              name="currency"
              id=""
            >
              <option value="">INR</option>
            </select>
          </div>
          <div className="flex gap-0 language-change-wrapper  text-xs md:text-base">
            <img
              draggable="false"
              className="h-full w-9 object-contain"
              src={translate}
              alt="translage"
            />
            <select
              className="rounded border p-1 bg-gray-100"
              name="language"
              id=""
            >
              <option value="">English</option>
            </select>
          </div>
        </div>
        <div className="top-design-pattern absolute left-0 top-full">
          <img
            src={topPattern}
            className="h-full w-full object-contain"
            draggable={false}
            alt=""
          />
        </div>
      </div>

      <Navmenu />
    </header>
  );
};

export default TopHeader;
