import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import pinkFlagStrip from "../../../assets/images/home/pink-flag-strip.png";
import SidePattern from "../../Home/SidePattern";
import logo from "../../../assets/images/home/Logo.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const footerLinks = [
  {
    title: "Policies",
    links: [
      {
        name: "Privacy Policy",
        redirect: "/privacy-policy",
      },
      {
        name: "Refund Policy",
        redirect: "/refund-policy",
      },
      {
        name: "Shipping Policy",
        redirect: "/shipping-policy",
      },
      {
        name: "Terms & Conditions",
        redirect: "terms-conditions",
      },
      {
        name: "Legal Notice",
        redirect: "/legal-notice",
      },
    ],
  },
  {
    title: "Quick Links",
    links: [
      {
        name: "About Us",
        redirect: "/about",
      },
      {
        name: "Contact Us",
        redirect: "/contact",
      },
      {
        name: "Shop",
        redirect: "/shop",
      },
      {
        name: "Your Cart",
        redirect: "/cart",
      },
      {
        name: "FAQ",
        redirect: "/faq",
      },
    ],
  },
];

const Footer = () => {
  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"));
  }, [location]);

  return (
    <>
      {!adminRoute && (
        <>
          <div className="pattern-section relative bg-primary-beige overflow-hidden">
            <img
              src={pinkFlagStrip}
              className="h-full w-full object-contain rotate-180"
              draggable={false}
              alt=""
            />
            <footer className="w-full py-1 sm:py-4 px-4 sm:px-12 text-primary-black text-base flex flex-col sm:flex-row justify-center gap-16">
              {/* <div className="w-1/12"></div> */}
              <div className="w-1/4">
                <div className="">
                  <img
                    draggable="false"
                    className={`h-full w-44 object-contain`}
                    src={logo}
                    alt="Mahahandloom.com mahahandloom"
                  />
                  <p className="mt-2 leading-5">
                    Maha Handloom is a renowned clothing brand based in Nagpur,
                    established in 1971. We are celebrated for our exquisite
                    collection of authentic sarees, along with enchanting
                    clothing materials and a luxury tie brand.
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-7/12 flex flex-col sm:flex-row gap-16">
                {footerLinks.map((el, i) => (
                  <div
                    className="w-full sm:w-1/3 flex flex-col gap-2 my-3 sm:my-6 ml-5"
                    key={i}
                  >
                    <h2 className="text-primary-pink font-semibold text-2xl mb-2 capitalize">
                      {el.title}
                    </h2>
                    {el.links.map((item, i) => (
                      <a
                        href={item.redirect}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                        key={i}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                ))}
                <div className="w-full sm:w-1/2 flex flex-col gap-2 my-3 sm:my-6 ml-5">
                  <h2 className="text-primary-pink font-semibold text-2xl mb-2 capitalize">
                    Join our email list
                  </h2>
                  <div className="relative">
                    <input
                      type="email"
                      placeholder="Your Email"
                      className="w-full pr-10 bg-transparent outline-none border-b-2 border-black bor"
                    />
                    <button className="group cursor-pointer absolute right-0 top-1/2 translate-y-[-50%] p-1">
                      <TrendingFlatIcon
                        fontSize="large"
                        className="group-hover:translate-x-1 transition-all"
                      />
                    </button>
                  </div>
                  <div className="mt-5">
                    <h2 className="text-primary-pink font-semibold text-2xl mb-2 capitalize">
                      Our Social
                    </h2>
                    <div className="social-media-icons">
                      <InstagramIcon
                        className="border-2 hover:p-[4px] transition-all border-primary-black rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
                        fontSize="large"
                      />
                      <FacebookIcon
                        className="border-2 hover:p-[4px] transition-all border-primary-black rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
                        fontSize="large"
                      />
                      <TwitterIcon
                        className="border-2 hover:p-[4px] transition-all border-primary-black rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
                        fontSize="large"
                      />
                      <YouTubeIcon
                        className="border-2 hover:p-[4px] transition-all border-primary-black rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
                        fontSize="large"
                      />
                      <LinkedInIcon
                        className="border-2 hover:p-[4px] transition-all border-primary-black rounded-full p-[6px] text-gray-700 mx-1 cursor-pointer"
                        fontSize="large"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* left flower pattern */}
              <SidePattern className={"absolute left-0"} side="left" />
              {/* right flower pattern  */}
              <SidePattern className={"absolute right-0"} side="right" />
            </footer>
            <img
              src={pinkFlagStrip}
              className="h-full w-full object-contain"
              draggable={false}
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
