import { useEffect } from "react";
import "./home.css";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getSliderProducts } from "../../actions/productAction";
import { useSnackbar } from "notistack";
import MetaData from "../Layouts/MetaData";
import bannerImg1 from "../../../src/assets/images/home/munga_saree.png";
import SidePattern from "./SidePattern";
import pinkFlagStrip from "../../assets/images/home/pink-flag-strip.png";
import flowerLineLtr from "../../assets/images/home/flower-line-ltr.png";
import flowerLineRtl from "../../assets/images/home/flower-line-rtl.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Home = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error, loading } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar]);

  return (
    <>
      <MetaData title="Mahahandloom | Home" />
      {/* <Categories /> */}
      <main className="gap-3">
        {/* <Banner /> */}
        {/*  ************ hero section ************   */}
        <section className="pattern-section h-[110vh] w-full overflow-hidden bg-primary-beige flex justify-center items-center">
          <div className="hero-slider-wrapper relative w-full h-full grid grid-cols-2 gap-1 p-14">
            <div className="hero-slider-content px-20 self-end mb-12">
              <h1 className="capitalize text-center text-primary-pink font-['Branch'] lg:text-7xxl  text-4xl">
                unleash your inner diva
              </h1>
              <h2 className="capitalize text-center text-primary-blue font-['Branch'] lg:text-7xxl  text-4xl">
                with our saree collections
              </h2>
              <div className="text-center">
                <button
                  type="button"
                  class="btn p-3 mt-14 text-primary-black capitalize text-2xl"
                >
                  Explore now
                </button>
              </div>
            </div>
            <div className="hero-slider-img flex items-end justify-center">
              <div
                className="relative w-8/12
              before:content-[''] before:absolute before:top-0 before:left-0 before:border-[3px] before:border-primary-pink before:w-full before:h-full before:translate-x-[-25px] before:translate-y-3 before:rounded-tr-full before:rounded-tl-full
              "
              >
                <img
                  draggable="false"
                  className="h-full w-full object-contain shadow-2xl rounded-tr-full rounded-tl-full"
                  src={bannerImg1}
                  alt="Munga Saree"
                />
              </div>
            </div>
            {/* left flower pattern */}
            <SidePattern className={"absolute left-0"} side="left" />
            {/* right flower pattern  */}
            <SidePattern className={"absolute right-0"} side="right" />
          </div>
        </section>
        {/*  ************ shop by categories section ************   */}
        <section className="pattern-section w-full overflow-hidden bg-primary-beige">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          <div className="relative">
            <img
              src={pinkFlagStrip}
              className="h-full w-full object-contain"
              draggable={false}
              alt=""
            />
            <div className="flex items-center justify-center flex-wrap gap-52 p-14">
              <div className="">
                <h5 className="uppercase text-center font-bold text-3xl text-primary-pink font-['Branch']">
                  Largest selectoin
                </h5>
                <p className="text-primary-pink text-base capitalize text-center font-medium">
                  of sarees with unique designs
                </p>
              </div>
              <div className="">
                <h5 className="uppercase text-center font-bold text-3xl text-primary-pink font-['Branch']">
                  Explore The
                </h5>
                <p className="text-primary-pink text-base capitalize text-center font-medium">
                  varities of sarees & cloths
                </p>
              </div>
              <div className="">
                <h5 className="uppercase text-center font-bold text-3xl text-primary-pink font-['Branch']">
                  Free shipping
                </h5>
                <p className="text-primary-pink text-base capitalize text-center font-medium">
                  all over india
                </p>
              </div>
            </div>
            <img
              src={pinkFlagStrip}
              className="h-full w-full object-contain rotate-180"
              draggable={false}
              alt=""
            />
          </div>
          <div className="py-5">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              shop by categories
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <p className="text-2xl p-3 text-center mt-auto text-black font-medium">
                      Sarees
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ shop by craft section ************   */}
        <section className="pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* flower line left to right  */}
          <img
            src={flowerLineLtr}
            className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
            draggable={false}
            alt=""
          />
          <div className="py-10">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              shop by craft
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] shadow-2xl rounded-t-full bg-white border border-primary-pink grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover rounded-t-full border border-primary-pink"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <p className="text-2xl p-3 text-center mt-auto text-black font-medium">
                      Banarasi
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ unique product section ************   */}
        <section className="pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* flower line left to right  */}
          <img
            src={flowerLineRtl}
            className="absolute top-0 translate-y-[-25%] z-10 h-auto w-full object-contain"
            draggable={false}
            alt=""
          />
          <div className="py-24">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              Unique Products
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] shadow-2xl rounded-t-full bg-white border border-primary-pink grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover rounded-t-full border border-primary-pink"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <div className="mt-auto p-3">
                      <p className="capitalize text-base text-center text-gray-700 font-medium">
                        Silk Dupion silk print saree
                      </p>
                      <p className="text-xl text-center text-black font-medium">
                        ₹2500
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ sarees section ************   */}
        <section className="pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* flower line left to right  */}
          <img
            src={flowerLineLtr}
            className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
            draggable={false}
            alt=""
          />
          <div className="py-10">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              Sarees
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <div className="mt-auto pt-5">
                      <p className="capitalize text-lg text-center text-gray-700 font-medium">
                        Red Sild Banarasi saree
                      </p>
                      <p className="text-xl text-center text-black font-medium">
                        ₹2500
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ mens wear section ************   */}
        <section className="pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* flower line left to right  */}
          <img
            src={flowerLineRtl}
            className="absolute top-0 translate-y-[-40%] z-10 h-auto w-full object-contain"
            draggable={false}
            alt=""
          />
          <div className="py-20">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              Mens Wear
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <div className="mt-auto pt-5">
                      <p className="capitalize text-lg text-center text-gray-700 font-medium">
                        Maroon Cotton Linen Kurta
                      </p>
                      <p className="text-xl text-center text-black font-medium">
                        ₹1619
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ home textile section ************   */}
        <section className="pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* flower line left to right  */}
          <img
            src={flowerLineLtr}
            className="absolute top-0 translate-y-[-50%] z-10 h-auto w-full object-contain"
            draggable={false}
            alt=""
          />
          <div className="py-10">
            <h2 className="font-['Branch'] capitalize mt-4 text-center text-7xxl font-medium text-primary-pink">
              Home Textile
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div
                    className=" w-full max-h-[28rem] grid grid-rows-2"
                    key={index}
                  >
                    <img
                      src={bannerImg1}
                      className="h-auto w-full object-cover"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <div className="mt-auto pt-5">
                      <p className="capitalize text-lg text-center text-gray-700 font-medium">
                        Handwoven wall art
                      </p>
                      <p className="text-xl text-center text-black font-medium">
                        ₹3290
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*  ************ Last section about us & social media updates ************   */}
        <section className="overflow-hidden pattern-section w-full bg-primary-beige relative">
          {/* left flower pattern */}
          <SidePattern className={"absolute left-0"} side="left" />
          {/* right flower pattern  */}
          <SidePattern className={"absolute right-0"} side="right" />
          {/* about us  */}
          <div className="bg-[#c0107f22] p-20 flex gap-10 overflow-hidden">
            <div className="w-[40%] p-5">
              <h2 className="font-['Branch'] capitalize text-7xl font-medium text-primary-pink">
                About us
              </h2>
              <p className="text-gray-700 font-base text-2xl py-5 ">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                autem repellendus doloribus sapiente blanditiis beatae, quidem
                laboriosam voluptatum odit magni sint labore deserunt officiis
                ab amet ad nemo natus praesentium!
              </p>
            </div>
            <div className="h-[25rem] w-[60%] p-5">
              <img
                src={bannerImg1}
                draggable={false}
                className="h-full w-full object-cover"
                alt=""
              />
            </div>
          </div>
          {/* social media updates  */}
          <div className="">
            <h2 className="font-['Branch']  text-center py-5 capitalize text-7xxl font-medium text-primary-pink">
              Social Media Updates
            </h2>
            <div className=" w-full grid grid-cols-4 gap-x-10 gap-y-5 px-24 py-7">
              {[1, 2, 3, 4].map((data, index) => {
                return (
                  <div className=" w-full h-[65vh] relative" key={index}>
                    <img
                      src={bannerImg1}
                      className="h-full w-full object-cover rounded-2xl"
                      draggable={false}
                      alt="mahandloom sarees"
                    />
                    <button className="group absolute bottom-4 right-4 hover:bg-primary-pink transition-all rounded-full btn bg-white p-3 flex items-center justify-center">
                      <PlayArrowIcon
                        fontSize="large"
                        className="text-black group-hover:text-white"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
